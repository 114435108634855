import React, { useEffect } from "react";

import {
  Flex,
  Fade,
  Heading,
  Button,
  Text,
  Container,
  Stack,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";

import { motion } from "framer-motion";

import { Header } from "../../components/Header";
import { Logo } from "../../components/Logo";

import Footer from "../../components/Footer";

import { HashLink } from "react-router-hash-link";

import CardPeople from "../../components/CardPeople";
import CardInstitution from "../../components/CardInstitution";

import { useTranslation } from "react-i18next";

const MotionFlex = motion(Flex);
const MotionContainer = motion(Container);

export default function About() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fade in={true}>
      <Header />
      <Flex
        direction="column"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        bgImage="/static/homepage/images/image04.png"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgAttachment="fixed"
        bgSize="cover"
      >
        <MotionFlex
          direction="column"
          w="100%"
          h="100vh"
          my="auto"
          mx="auto"
          px="6"
          justify="center"
          textAlign="center"
          alignItems="center"
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <Logo color="white" />

          <Heading
            py={6}
            fontWeight={600}
            maxW={1000}
            fontSize={{ base: "2xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color="white"
          >
            {t("about")} <br />
          </Heading>

          <Flex direction={{ base: "column", sm: "row", md: "row" }}>
            <HashLink to="#startText">
              <Button
                m="10px"
                w="200px"
                colorScheme={"blue"}
                bg={"blue.700"}
                rounded={"full"}
                px={6}
                _hover={{
                  bg: "blue.500",
                }}
              >
                {t("button_knowmore")}
              </Button>
            </HashLink>
          </Flex>
        </MotionFlex>
      </Flex>

      <MotionContainer
        maxW={"4xl"}
        transition={{
          type: "spring",
          damping: 20,
          stiffness: 100,
        }}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
      >
        <Stack id="startText" as={Box} textAlign={"center"} py={20}>
          <Heading
            py={2}
            fontWeight={600}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t1")}
          </Heading>

          <Text
            textAlign="justify"
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            style={{ textIndent: 50 }}
            color={"blue.700"}
          >
            {t("about_p1")}
          </Text>
          <Text
            textAlign="justify"
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            style={{ textIndent: 50 }}
            color={"blue.700"}
          >
            {t("about_p2")}
          </Text>
        </Stack>

        <Stack as={Box} textAlign={"left"} pb={20}>
          <Heading
            pb={2}
            fontWeight={600}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t2")}
          </Heading>
          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_marcos_heil_costa.jpg"
              name="Prof. Marcos Heil Costa, Ph.D, UFV"
              url="http://lattes.cnpq.br/8463458737785676"
            />
          </SimpleGrid>
        </Stack>

        <Stack as={Box} textAlign={"left"} pb={20}>
          <Heading
            fontWeight={600}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t3")}
          </Heading>

          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_fernando_martins_pimenta.jpg"
              name={`${t("eng_m")} Fernando Martins Pimenta, UFV, ${t(
                "consultant"
              )}`}
              url="http://lattes.cnpq.br/0646984654461300"
            />

            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_matheus_lucas_da_silva.jpg"
              name={`${t("bel")} Matheus Lucas da Silva, Univiçosa, ${t(
                "consultant"
              )}`}
              url="http://lattes.cnpq.br/8832919426314691"
            />
          </SimpleGrid>
        </Stack>

        <Stack as={Box} textAlign={"left"} pb={20}>
          <Heading
            pb={10}
            fontWeight={600}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t4")}
          </Heading>

          <Heading
            py={10}
            fontWeight={500}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t41")}
          </Heading>

          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_vitor_cunha_fontes.jpg"
              name={`${t("bel")} Vitor Cunha Fontes, MS, UFV`}
              url="http://lattes.cnpq.br/8473023262138609"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_marina_castro.jpg"
              name={`Marina Castro ${t("scholarship_cnpq")}`}
              url="http://lattes.cnpq.br/0011648210192762"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_allan_turini_speroto.jpg"
              name={`Allan Turini Speroto ${t("scholarship_funarbe")}`}
              url="http://lattes.cnpq.br/3979875286653323"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_veronica_versieux.jpg"
              name={`Verônica Versieux ${t("scholarship_cnpq")}`}
              url="http://lattes.cnpq.br/3924871020701558"
            />
          </SimpleGrid>

          <Heading
            py={10}
            fontWeight={500}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t42")}
          </Heading>

          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_luiz_commar.jpg"
              name={`${t("eng_m")} Luiz Felipe Sant’Anna Commar, MS ${t(
                "scholarship_cnpq"
              )}`}
              url="http://lattes.cnpq.br/1129779516844866"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_marconi_vital.jpg"
              name={`Marconi Moreira Vital ${t("scholarship_cnpq")}`}
              url="http://lattes.cnpq.br/8605551468262424"
            />
          </SimpleGrid>

          <Heading
            py={10}
            fontWeight={500}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t43")}
          </Heading>

          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_flavia_vitorino.jpg"
              name={`Flávia Barros Vitorino ${t("scholarship_cnpq")}`}
              url="http://lattes.cnpq.br/9363395234754034"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_raphael_pousa.jpg"
              name={`${t("bel")} Raphael Pousa dos Santos, MS, UFV`}
              url="http://lattes.cnpq.br/8704223943058364"
            />
          </SimpleGrid>

          <Heading
            py={10}
            fontWeight={500}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t44")}
          </Heading>

          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_aziz_galvao.jpg"
              name="Prof. Aziz Galvão da Silva Jr., Ph.D., UFV"
              url="http://lattes.cnpq.br/5151214329134966"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_everardo_mantovani.jpg"
              name="Prof. Everardo Chartuni Mantovani, DSc, UFV"
              url="http://lattes.cnpq.br/7532968937685437"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_igor_boninsenha.jpg"
              name={`${t("eng_m")} Igor Boninsenha, MS ${t(
                "scholarship_cnpq"
              )}`}
              url="http://lattes.cnpq.br/9360049694442293"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_daiane_debora_resende.jpg"
              name={`Daiana Debora Resende ${t("scholarship_cnpq")}`}
              url="http://lattes.cnpq.br/9955309408604839"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_ingrid_nataly_tavares_da_silva.jpg"
              name={`Ingrid Nataly Tavares da Silva ${t("scholarship_cnpq")}`}
              url="http://lattes.cnpq.br/6889598134779165"
            />
          </SimpleGrid>

          <Heading
            py={10}
            fontWeight={500}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t45")}
          </Heading>

          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_ana_beatriz.jpg"
              name={`${t("eng_f")} Ana Beatriz dos Santos, MS, UFV`}
              url="http://lattes.cnpq.br/1277129685383769"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_emily_ane_dionizio.jpg"
              name={`${t("bel")} Emily Ane Dionizio, DSc, UFV`}
              url="http://lattes.cnpq.br/5616171526303170"
            />
          </SimpleGrid>

          <Heading
            py={10}
            fontWeight={500}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t46")}
          </Heading>

          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_gerson_cardoso.jpg"
              name="Prof. Gerson Cardoso da Silva Jr., DSc, UFRJ"
              url="http://lattes.cnpq.br/4103236636522942"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_eduardo_antonio_gomes_marques.jpg"
              name="Prof. Eduardo A. G. Marques, DSc, UFV"
              url="http://lattes.cnpq.br/6725413897416818"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_glauco_eger.jpg"
              name="Geol. Glauco Eger, DSc, UFRJ"
              url="http://lattes.cnpq.br/6905543686272791"
            />
          </SimpleGrid>

          <Heading
            py={10}
            fontWeight={500}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t47")}
          </Heading>

          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_aziz_galvao.jpg"
              name="Prof. Aziz Galvão da Silva Jr., Ph.D., UFV"
              url="http://lattes.cnpq.br/5151214329134966"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_everardo_mantovani.jpg"
              name="Prof. Everardo Chartuni Mantovani, DSc, UFV"
              url="http://lattes.cnpq.br/7532968937685437"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_marina_castro.jpg"
              name="Marina Castro (Bolsista CNPq, UFV)"
              url="http://lattes.cnpq.br/0011648210192762"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_veronica_versieux.jpg"
              name="Verônica Versieux (Bolsista CNPq, UFV)"
              url="http://lattes.cnpq.br/3924871020701558"
            />
          </SimpleGrid>
        </Stack>

        <Stack as={Box} textAlign={"left"} pb={20}>
          <Heading
            pb={10}
            fontWeight={600}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t5")}
          </Heading>
          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_everardo_mantovani.jpg"
              name="Prof. Everardo Chartuni Mantovani, DSc, UFV"
              url="http://lattes.cnpq.br/7532968937685437"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_marcos_heil_costa.jpg"
              name="Prof. Marcos Heil Costa, Ph.D., UFV"
              url="http://lattes.cnpq.br/8463458737785676"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_aziz_galvao.jpg"
              name="Prof. Aziz Galvão da Silva Jr., Ph.D., UFV"
              url="http://lattes.cnpq.br/5151214329134966"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_gerson_cardoso.jpg"
              name="Prof. Gerson Cardoso da Silva Jr., DSc, UFRJ"
              url="http://lattes.cnpq.br/4103236636522942"
            />
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_eduardo_antonio_gomes_marques.jpg"
              name="Prof. Eduardo A. G. Marques, DSc, UFV"
              url="http://lattes.cnpq.br/6725413897416818"
            />
          </SimpleGrid>
        </Stack>

        <Stack as={Box} textAlign={"left"} pb={20}>
          <Heading
            pb={10}
            fontWeight={600}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t6")}
          </Heading>
          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="300px"
            spacingY={10}
            align="flex-start"
          >
            <CardPeople
              avatarsrc="http://obahia.dea.ufv.br/static/geonode/img/fotos/foto_gabrielle_pires.jpg"
              name={`${t("prof_f")} Gabrielle Ferreira Pires, DSc, UFV`}
              url="http://lattes.cnpq.br/9576327311464149"
            />
          </SimpleGrid>
        </Stack>

        <Stack as={Box} textAlign={"left"} pb={20}>
          <Heading
            pb={10}
            fontWeight={600}
            fontSize={{
              base: "1rem",
              sm: "1rem",
              md: "1rem",
              lg: "1.5rem",
            }}
            textAlign="left"
            color="blue.700"
            lineHeight={"110%"}
          >
            {t("about_t7")}
          </Heading>
          <SimpleGrid
            flex="1"
            gap="4"
            minChildWidth="200px"
            spacingY={2}
            align="flex-start"
          >
            <CardInstitution
              imgsrc="http://obahia.dea.ufv.br/static/geonode/img/logoUFV500x300.png"
              name="UFV"
              url="https://www.ufv.br/"
            />
            <CardInstitution
              imgsrc="http://obahia.dea.ufv.br/static/geonode/img/logoUFRJ500x300.png"
              name="UFRJ"
              url="https://ufrj.br"
            />
            <CardInstitution
              imgsrc="http://obahia.dea.ufv.br/static/geonode/img/logo_aiba500x300.png"
              name="AIBA"
              url="https://aiba.org.br/"
            />
            <CardInstitution
              imgsrc="http://obahia.dea.ufv.br/static/geonode/img/logo_prodeagro500x300.png"
              name="PRODEAGRO"
              url="http://www.seagri.ba.gov.br/content/prodeagro"
            />
            <CardInstitution
              imgsrc="https://logodownload.org/wp-content/uploads/2016/10/cnpq-logo-7.png"
              name="CNPQ"
              url="https://www.gov.br/cnpq"
            />
            <CardInstitution
              imgsrc="http://obahia.dea.ufv.br/static/geonode/img/logo_funarbe500x300.png"
              name="FUNARBE"
              url="https://funarbe.org.br"
            />
          </SimpleGrid>
        </Stack>
      </MotionContainer>

      <Footer />
    </Fade>
  );
}
