import React from "react";

import { Heading, Box, Link, Avatar } from "@chakra-ui/react";

import { motion } from "framer-motion";
interface CardToolProps {
  avatarsrc: string;
  name: string;
  url: string;
}

const MotionBox = motion(Box);

export default function CardPeople({ avatarsrc, name, url }: CardToolProps) {
  return (
    <MotionBox
      maxW={300}
      minW={300}
      bg="white"
      rounded={"lg"}
      p={2}
      mt={2}
      mx="auto"
      textAlign={"center"}
      whileHover={{ scale: 1.1 }}
      transition={{
        type: "expand",
        damping: 20,
        stiffness: 100,
      }}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
    >
      <Link href={url} _focus={{ outline: 0 }} target="_blank">
        <Avatar size="2xl" src={avatarsrc} alt={name} mb={2} />
      </Link>

      <Heading
        fontWeight={200}
        fontSize={{
          base: "1rem",
          sm: "1rem",
          md: "1rem",
          lg: "1.5rem",
        }}
        color={"blue.700"}
      >
        {name}
      </Heading>
    </MotionBox>
  );
}
