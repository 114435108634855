import React, { useState, useEffect, useCallback } from "react";

import ReactCountryFlag from "react-country-flag";

import i18n from "../../i18n";

const ChangeLanguage = () => {
  const [flag, setFlag] = useState("BR");

  const handleLanguage = useCallback((locale) => {
    i18n.changeLanguage(locale);
  }, []);

  const handleFlag = useCallback(() => {
    if (flag === "US") {
      setFlag("BR");
      handleLanguage("en");
    } else {
      setFlag("US");
      handleLanguage("pt");
    }
  }, [flag, handleLanguage]);

  useEffect(() => {
    const language = window.navigator.language.split("-")[0];

    if (language === "en") {
      setFlag("BR");
    } else {
      setFlag("US");
    }
  }, []);

  return (
    <ReactCountryFlag
      countryCode={flag}
      svg
      onClick={handleFlag}
      style={{
        fontSize: "25px",
        cursor: "pointer",
      }}
    />
  );
};

export default ChangeLanguage;
