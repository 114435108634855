import React from "react";

import {
  Box,
  Heading,
  Container,
  Text,
  ListIcon,
  ListItem,
  List,
  Stack,
  Link,
} from "@chakra-ui/react";

import { MdCheckCircle } from "react-icons/md";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";

import { linkdata, publications } from "./data";

const MotionContainer = motion(Container);

type SectionTextProps = {
  id?: string;
};

export default function GovernanceContent({ id }: SectionTextProps) {
  const { t } = useTranslation();

  return (
    <MotionContainer
      maxW={"3xl"}
      transition={{
        type: "spring",
        damping: 20,
        stiffness: 100,
      }}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
    >
      <Stack id={id} as={Box} textAlign={"center"} py={20}>
        <Heading
          py={2}
          fontWeight={600}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t1")}
        </Heading>

        <Heading
          py={2}
          fontWeight={400}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t11")}
        </Heading>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p1")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p2")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p3")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p4")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p5")}
        </Text>

        <Heading
          py={2}
          fontWeight={400}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t12")}
        </Heading>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p6")}
        </Text>

        <Heading
          py={2}
          fontWeight={400}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t13")}
        </Heading>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p7")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p8")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p9")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p10")}
        </Text>

        <Heading
          py={2}
          fontWeight={400}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t14")}
        </Heading>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p11")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p12")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p13")}
        </Text>

        <Heading
          py={2}
          fontWeight={400}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t15")}
        </Heading>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p14")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p15")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p16")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p17")}
        </Text>

        <Heading
          py={2}
          fontWeight={400}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t16")}
        </Heading>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p18")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p19")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p20")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p21")}
        </Text>

        <Heading
          py={2}
          fontWeight={400}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t17")}
        </Heading>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p22")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p23")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p24")}
        </Text>

        <Heading
          py={2}
          fontWeight={400}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t18")}
        </Heading>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p25")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p26")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p27")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p28")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p29")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p30")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p31")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p32")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("governance_p33")}
        </Text>

        <Heading
          py={2}
          fontWeight={400}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t19")}
        </Heading>

        <List
          spacing={3}
          py={5}
          pl={12}
          color="blue.700"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
        >
          {publications.map((o) => (
            <ListItem
              textAlign="left"
              key={o.name}
              _hover={{
                transition: "0.2s",
                color: "blue.400",
              }}
            >
              <ListIcon as={MdCheckCircle} color="green.500" />
              <Link href={o.url}>{o.name}</Link>
            </ListItem>
          ))}
        </List>
      </Stack>

      <Stack id={id} as={Box} textAlign="left" pb={20}>
        <Heading
          py={2}
          fontWeight={600}
          fontSize="2rem"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("governance_t2")}
        </Heading>

        <List
          spacing={3}
          py={5}
          pl={12}
          color="blue.700"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
        >
          {linkdata.map((o) => (
            <ListItem
              key={o.name}
              _hover={{
                transition: "0.2s",
                color: "blue.400",
              }}
            >
              <ListIcon as={MdCheckCircle} color="green.500" />
              <Link href={o.url}>{o.name}</Link>
            </ListItem>
          ))}
        </List>
      </Stack>
    </MotionContainer>
  );
}
