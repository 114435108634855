import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import About from "../pages/About";
import Info from "../pages/Info";
import Governance from "../pages/Governance";
import RuralProfit from "../pages/RuralProfit";

const Routes: React.FC = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/about" component={About} />
    <Route path="/info" component={Info} />
    <Route path="/governance" component={Governance} />
    <Route path="/ruralprofit" component={RuralProfit} />
  </Switch>
);

export default Routes;
