import React from "react";

import { Box, Heading, Container, Text, Stack } from "@chakra-ui/react";

import { motion } from "framer-motion";

import { useTranslation } from "react-i18next";

import YouTube from "react-youtube";

const MotionContainer = motion(Container);
const MotionBox = motion(Box);

type SectionTextProps = {
  id?: string;
};

export default function InformationContent({ id }: SectionTextProps) {
  const { t } = useTranslation();

  return (
    <MotionContainer
      maxW={"3xl"}
      transition={{
        type: "spring",
        damping: 20,
        stiffness: 100,
      }}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
    >
      <Stack id={id} as={Box} textAlign={"center"} py={20}>
        <Heading
          py={2}
          fontWeight={600}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("info_t1")}
        </Heading>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("info_p1")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("info_p2")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("info_p3")}
        </Text>

        <Text
          textAlign="justify"
          fontSize={{
            base: "1rem",
            sm: "1rem",
            md: "1.5rem",
            lg: "1.5rem",
          }}
          style={{ textIndent: 50 }}
          color={"blue.700"}
        >
          {t("info_p4")}
        </Text>

        <Heading
          py={2}
          fontWeight={600}
          fontSize="2rem"
          textAlign="left"
          color="blue.700"
          lineHeight={"110%"}
        >
          {t("info_t2")}
        </Heading>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="eTRozu4gt4U"
          />
        </MotionBox>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="zHribwpkryo"
          />
        </MotionBox>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="0s1iNNinIA0"
          />
        </MotionBox>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="0Zklepemojc"
          />
        </MotionBox>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="o0rfFoBDfYI"
          />
        </MotionBox>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="DrF1ZfJYPmg"
          />
        </MotionBox>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="ldUHpPZ5usQ"
          />
        </MotionBox>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="tPn7WuLdxSI"
          />
        </MotionBox>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="HfmZf-lrqN4"
          />
        </MotionBox>

        <MotionBox
          pb={5}
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <YouTube
            opts={{
              width: "100%",
              height: "480px",
            }}
            videoId="nzum5muG9eI"
          />
        </MotionBox>
      </Stack>
    </MotionContainer>
  );
}
