import React from "react";

import { Box, Link, Image } from "@chakra-ui/react";

import { motion } from "framer-motion";
interface CardToolProps {
  imgsrc: string;
  name: string;
  url: string;
}

const MotionBox = motion(Box);

export default function CardInstitution({ imgsrc, name, url }: CardToolProps) {
  return (
    <MotionBox
      maxW={300}
      minW={200}
      height={200}
      bg="white"
      rounded={"lg"}
      align="center"
      p={2}
      mt={2}
      mx="auto"
      whileHover={{ scale: 1.1 }}
      transition={{
        type: "expand",
        damping: 20,
        stiffness: 100,
      }}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
    >
      <Link href={url} _focus={{ outline: 0 }}>
        <Image boxSize="200px" objectFit="contain" src={imgsrc} alt={name} />
      </Link>
    </MotionBox>
  );
}
