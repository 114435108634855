import React from "react";

type LogoProps = {
  color?: string;
  height?: string;
};

export const Logo = ({ color = "blue.700", height = "80px" }: LogoProps) => {
  return (
    <svg
      height={height}
      version="1.1"
      viewBox="0 0 115.42 35.209"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(-81.079 -74.421)" fill={color}>
        <g transform="scale(1.0677 .93661)" aria-label="Bahia">
          <path d="m127.81 103.48c0-2.5404-1.3224-5.2199-5.6723-5.9507 2.8884-0.66119 4.7675-2.5404 4.7675-5.3243 0-3.1668-2.088-6.1943-7.9691-6.1943h-6.9599c-1.218 0-1.566 0.3132-1.566 1.566v21.019c0 1.218 0.31319 1.566 1.566 1.566h7.8299c6.0899 0 8.0039-3.4452 8.0039-6.6815zm-4.7675-11.205c0 2.1924-1.3224 4.2107-5.1503 4.2107h-3.4452v-7.8647h3.4452c0.76559 0 5.1503 0 5.1503 3.6539zm0.80039 11.136c0 2.7144-1.8444 4.1411-5.0807 4.1411h-4.3151v-8.8391h3.7584c0.97438 0 5.6375 0 5.6375 4.6979z" />
          <path d="m143.88 108.63v-9.9875c0-3.2364-1.8444-5.4287-6.0551-5.4287-1.9488 0-3.3408 0.38279-4.7675 1.0788-0.66119 0.348-0.83519 0.41759-0.83519 0.80039 0 0.2436 0.0696 0.97439 0.1044 1.2528 0.0696 0.80039 0.1044 1.0092 0.45239 1.0092 0.174 0 0.2088-0.0348 0.5568-0.348 1.4268-1.392 3.0276-1.74 4.3499-1.74 0.76559 0 2.3316 0 2.3316 3.4104v0.69599c0 0.59159-0.0348 0.62639-0.38279 0.66119-1.4964 0.0696-8.6999 0.3828-8.6999 5.5331 0 2.5056 1.8444 4.9415 4.5587 4.9415 2.5056 0 4.0716-1.74 4.6283-2.7492v0.86999c0 1.0788 0.2088 1.5312 1.5312 1.5312h0.69599c1.218 0 1.5312-0.34799 1.5312-1.5312zm-3.8628-3.8627c0 3.1668-2.5056 3.5148-3.0972 3.5148-1.392 0-2.3316-1.3224-2.3316-2.784 0-3.5496 3.7583-4.1411 5.4287-4.2455z" />
          <path d="m160.34 108.63v-10.37c0-2.784-0.86998-4.8719-4.4543-4.8719-2.784 0-4.1759 2.0184-4.7675 3.306h-0.0348v-9.1523c0-1.0788-0.2088-1.5312-1.5312-1.5312h-0.66119c-1.218 0-1.566 0.3132-1.566 1.566v21.019c0 1.218 0.31319 1.566 1.566 1.566h0.76559c1.218 0 1.5312-0.34799 1.5312-1.5312v-8.3171c0-3.1668 1.7748-4.6979 3.4104-4.6979 1.4616 0 1.8792 0.83519 1.8792 2.784v10.231c0 1.0788 0.2088 1.5312 1.5312 1.5312h0.80039c1.218 0 1.5312-0.34799 1.5312-1.5312z" />
          <path d="m167.91 108.63v-13.363c0-1.0788-0.2088-1.5312-1.5312-1.5312h-0.66119c-1.218 0-1.566 0.3132-1.566 1.566v13.293c0 1.218 0.3132 1.566 1.566 1.566h0.66119c1.218 0 1.5312-0.34799 1.5312-1.5312zm0.27839-19.627v-0.83519c0-1.3224-0.34799-1.6356-1.566-1.6356h-1.1484c-1.1832 0-1.566 0.2784-1.566 1.6356v0.83519c0 1.2528 0.31319 1.6356 1.566 1.6356h1.1484c1.218 0 1.566-0.348 1.566-1.6356z" />
          <path d="m184.04 108.63v-9.9875c0-3.2364-1.8444-5.4287-6.0551-5.4287-1.9488 0-3.3408 0.38279-4.7675 1.0788-0.66119 0.348-0.83518 0.41759-0.83518 0.80039 0 0.2436 0.0696 0.97439 0.10439 1.2528 0.0696 0.80039 0.1044 1.0092 0.4524 1.0092 0.174 0 0.20879-0.0348 0.55679-0.348 1.4268-1.392 3.0276-1.74 4.3499-1.74 0.76559 0 2.3316 0 2.3316 3.4104v0.69599c0 0.59159-0.0348 0.62639-0.3828 0.66119-1.4964 0.0696-8.6999 0.3828-8.6999 5.5331 0 2.5056 1.8444 4.9415 4.5587 4.9415 2.5056 0 4.0715-1.74 4.6283-2.7492v0.86999c0 1.0788 0.2088 1.5312 1.5312 1.5312h0.69599c1.218 0 1.5312-0.34799 1.5312-1.5312zm-3.8627-3.8627c0 3.1668-2.5056 3.5148-3.0972 3.5148-1.392 0-2.3316-1.3224-2.3316-2.784 0-3.5496 3.7584-4.1411 5.4287-4.2455z" />
        </g>
        <g strokeWidth=".261">
          <path d="m81.079 92.13 14.735 2.6831 2.6371-2.6831z" />
          <g transform="translate(39.833,-5.4193)" fillOpacity=".7451">
            <path
              d="m41.246 97.347 14.735-2.6831 2.6371 2.6831z"
              fill={color}
              fillOpacity=".7451"
              strokeWidth=".261"
            />
          </g>
          <path
            d="m98.582 74.425-2.6831 14.735 2.6831 2.6371z"
            fillOpacity=".39216"
          />
        </g>
        <g transform="rotate(90,81.475,114.65)" fillOpacity=".078431">
          <path
            d="m41.246 97.347 14.735-2.6831 2.6371 2.6831z"
            fill={color}
            fillOpacity=".078431"
          />
        </g>
        <g strokeWidth=".261">
          <path
            d="m116.29 91.93-14.735-2.6831-2.6371 2.6831z"
            fillOpacity=".39216"
          />
          <g transform="rotate(180,78.766,94.738)" fillOpacity=".078431">
            <path
              d="m41.246 97.347 14.735-2.6831 2.6371 2.6831z"
              fill={color}
              fillOpacity=".078431"
              strokeWidth=".261"
            />
          </g>
          <path
            d="m98.783 109.63 2.6831-14.735-2.6831-2.6372z"
            fillOpacity=".39216"
          />
          <g transform="rotate(-90,76.056,74.817)" fillOpacity=".078431">
            <path
              d="m41.246 97.347 14.735-2.6831 2.6371 2.6831z"
              fill={color}
              fillOpacity=".078431"
              strokeWidth=".261"
            />
          </g>
        </g>
        <path d="m99.531 75.523 0.6413 3.1021a13.494 13.494 0 0 1 11.9 11.935l3.099 0.63872a16.536 16.536 0 0 0-15.64-15.676zm-1.7177 0.0134a16.536 16.536 0 0 0-15.63 15.611l3.0949-0.63976a13.494 13.494 0 0 1 11.896-11.872zm-15.63 17.344a16.536 16.536 0 0 0 15.59 15.654l-0.64234-3.1078a13.494 13.494 0 0 1-11.848-11.907zm32.999 0.0279-3.1052 0.64182a13.494 13.494 0 0 1-11.943 11.877l-0.6382 3.098a16.536 16.536 0 0 0 15.687-15.617z" />
      </g>
    </svg>
  );
};
