import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Heading, Button, SimpleGrid } from "@chakra-ui/react";

import { HashLink } from "react-router-hash-link";

import {
  GiStack,
  GiRaining,
  GiMeshBall,
  GiEarthAmerica,
  GiMoneyStack,
  GiShakingHands,
} from "react-icons/gi";

import { MdTrendingDown } from "react-icons/md";

import { motion } from "framer-motion";

import { Header } from "../../components/Header";
import { Logo } from "../../components/Logo";

import CardTool from "../../components/CardTool";

import Footer from "../../components/Footer";

import { useTranslation } from "react-i18next";

const MotionFlex = motion(Flex);

export default function Home() {
  const { t } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction="column" justifyContent="center">
      <Header />

      <Flex
        direction="column"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        bgImage="/static/homepage/images/image01.png"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgAttachment="fixed"
        bgSize="cover"
      >
        <MotionFlex
          direction="column"
          w="100%"
          h="100vh"
          my="auto"
          mx="auto"
          px="6"
          justify="center"
          textAlign="center"
          alignItems="center"
          transition={{
            type: "spring",
            damping: 20,
            stiffness: 100,
          }}
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <Logo color="white" />

          <Heading
            py={6}
            fontWeight={600}
            maxW={800}
            fontSize={{ base: "2xl", sm: "4xl", md: "5xl" }}
            lineHeight={"110%"}
            color="white"
          >
            {t("principal")} <br />
          </Heading>

          <Flex direction={{ base: "column", sm: "column", md: "row" }}>
            <HashLink to="#tools">
              <Button
                m="10px"
                w="200px"
                colorScheme={"blue"}
                bg={"#1f5582"}
                rounded={"full"}
                px={6}
                _hover={{
                  bg: "blue.500",
                }}
              >
                {t("button_tools")}
              </Button>
            </HashLink>

            <Button
              m="10px"
              w="200px"
              onClick={() => history.push("info")}
              colorScheme={"blue"}
              bg={"#1f5582"}
              rounded={"full"}
              px={6}
              _hover={{
                bg: "blue.500",
              }}
            >
              {t("menu_info")}
            </Button>

            <Button
              m="10px"
              w="200px"
              onClick={() => history.push("about")}
              colorScheme={"blue"}
              bg={"#1f5582"}
              rounded={"full"}
              px={6}
              _hover={{
                bg: "blue.500",
              }}
            >
              {t("menu_about")}
            </Button>
          </Flex>
        </MotionFlex>
      </Flex>

      <MotionFlex
        id="tools"
        h={["100vh", "100vh", "100vh", "100vh"]}
        p={6}
        justify="center"
        textAlign="center"
        alignItems="center"
        transition={{
          type: "expand",
          damping: 20,
          stiffness: 100,
        }}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
      >
        <SimpleGrid
          flex="1"
          gap="4"
          minChildWidth={{
            base: "100px",
            sm: "100px",
            md: "320px",
            lg: "320px",
          }}
          spacingY={10}
          align="flex-start"
        >
          <CardTool
            icon={GiEarthAmerica}
            title={t("title_tool_mapserver")}
            content={t("tool_mapserver")}
            url="http://obahia.dea.ufv.br/maps/38/view"
          />

          <CardTool
            icon={GiStack}
            title={t("title_tool_timeseries")}
            content={t("tool_timeseries")}
            url="http://obahia.dea.ufv.br/landuse"
          />

          <CardTool
            icon={GiRaining}
            title={t("title_tool_onset")}
            content={t("tool_onset")}
            url="http://obahia.dea.ufv.br/onset"
          />

          <CardTool
            icon={MdTrendingDown}
            title={t("title_tool_flow")}
            content={t("tool_flow")}
            url="http://obahia.dea.ufv.br/stream"
          />

          <CardTool
            icon={GiMeshBall}
            title={t("title_tool_modflow")}
            content={t("tool_modflow")}
            url="http://obahia.dea.ufv.br/mfview"
          />

          <CardTool
            icon={GiMoneyStack}
            title={t("title_tool_profit")}
            content={t("tool_profit")}
            url="ruralprofit"
            isInternalLink={true}
          />

          <CardTool
            icon={GiShakingHands}
            title={t("title_tool_governance")}
            content={t("tool_governance")}
            url="governance"
            isInternalLink={true}
          />
        </SimpleGrid>
      </MotionFlex>

      <Footer />
    </Flex>
  );
}
