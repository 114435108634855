import React from "react";

import { ChakraProvider } from "@chakra-ui/react";

import { theme } from "../src/styles/theme";

import { HashRouter } from "react-router-dom";
import Routes from "./routes/index";

export const App = () => (
  <ChakraProvider theme={theme}>
    <HashRouter>
      <Routes />
    </HashRouter>
  </ChakraProvider>
);
